export default {
  namespaced: true,
  state: {
    id: '',
    first_name: '',
    last_name: '',
    email: '',
    gender: '',
    phone_number: '',
    avatar: '',
    token: null,
    document: '',
  },
  getters: {
    getProfileData(state) {
      return {
        id: state.id,
        first_name: state.first_name,
        last_name: state.last_name,
        email: state.email,
        gender: state.gender,
        phone_number: state.phone_number,
        document: state.document,
      }
    },
    getToken(state) {
      return {
        token: state.token,
      }
    },
  },
  mutations: {
    SET_PROFILE_DATA(state, profileData) {
      state.id = profileData.id
      state.first_name = profileData.first_name
      state.last_name = profileData.last_name
      state.email = profileData.email
      state.gender = profileData.gender
      state.phone_number = profileData.phone_number
      state.document = profileData.document
    },
    SET_TOKEN_PASSWORD(state, token) {
      state.token = token
    },
  },
  actions: {
    setProfileData(context, profileData) {
      context.commit('SET_PROFILE_DATA', profileData)
    },
    setTokenPassword(context, token) {
      context.commit('SET_TOKEN_PASSWORD', token)
    },
  },
}
